.Background {
    background: #FFFFFF;
    border-radius: 10px 10px 0 0;
    padding: 0 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none; 
    scrollbar-width: none; 
}

.Feedback-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    border-top: 1px solid #E9EEF4;
    padding: 25px 0px 25px 0px;
    gap: 15px;
}

.Title-text {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.Title-text h3{
    font-weight: 500;
    font-size: calc((100vw - 320px)/(425 - 320) * (15 - 13) + 13px);
    line-height: 18.15px;
    color: #0A0930;
}

.Title-text p {
    font-weight: 500;
    font-size: calc((100vw - 320px)/(425 - 320) * (10 - 8) + 8px);
    line-height: 12.1px;
    color: #616161;
}

.choose-feedback-message{
    display: flex;
    justify-content: center;
    gap: 10px;
}

.choose-feedback-message button{
    width: 100%;
    border: none;
    border-radius: 10px;
    padding: 10px 0;
    font-size: calc((100vw - 320px)/(425 - 320) * (13 - 11) + 11px);
}

.choose-feedback-message .Yes{
    background: #523DD8;
    color: #FFFFFF;

}

.choose-feedback-message .No{
    background: #D6D6D6;
}

.map {
    padding: 0 50px;
}

.walk-assessment {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title-text-assessment {
    border-top: 1px solid #E9EEF4;
    padding: 35px 0;
    text-align: center;
    font-weight: 400;
    line-height: 14.52px;
    font-size: calc((100vw - 320px)/(425 - 320) * (12 - 10) + 10px);
    color: #797984;
    padding-bottom: 20px;
}

.walk-grade {
    display: flex;
    border-top: 1px solid #E9EEF4;
    flex-direction: column;
    gap: 15px;
    text-align: center;
}

.walk-grade h3 {
    padding-top: 15px;
}

.grade-faces{
    display: flex;
    gap: 15px;
    justify-content: center;
}

.feedback {
    font-size: 12px;
    color: #797984;
}

.walk-grade h3 {
    font-size: calc((100vw - 320px)/(425 - 320) * (15 - 13) + 13px);
    line-height: 18.15px;
    font-weight: 400;
    color: #0A0930;
}

.grade-submit {
    display: flex;
    gap: 10px;
    justify-content: center;
    background: #51D0EC;
    color: #FFFFFF;
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 10px;
}

.grade-companions {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-top: 1px solid #E9EEF4;
    margin-bottom: 10px;
}

.header-grade-companions {
    padding-top: 15px;
    text-align: center;
    font-weight: 500;
    font-size: calc((100vw - 320px)/(425 - 320) * (15 - 13) + 13px);
    line-height: 18.15px;
    color: #0A0930;
}

.grade-companions-logo {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}
.companions-avatar {
    border-radius: 50px;
    width: 100%;
    height: 100%;
}
.companions-level {
    position: relative;
    top: -9px;
    left: 9px;
    border-radius: 10px;
    background-color: #fff;
    width: 32px;
    height: 10px;
    font-size: 8px;
    line-height: 8px;
    font-weight: 1000;
    color: #523DD8;
    text-align: center;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
}

.full-data-companion {
    display: flex;
    align-items: center;
    gap: 10px;
}

.user-data-companion {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: row;
}

.user-data-companion p {
    font-weight: 300;
    font-size: 12px;
    line-height: 14.06px;
    color: rgba(10, 9, 48, 1);
}

.user-age-sex {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    background: rgba(207, 218, 255, 0.9);
    padding: 5px 10px 5px 10px;
}

.user-age-sex p{
    color: rgba(9, 18, 219, 1);
    font-weight: 300;
    font-size: 12px;
    line-height: 14.06px;
}

.user-age-sex img {
    width: 10px;
}

.user-data-position {
    display: flex;
    flex-direction: column;
    gap: 10px;    
}

.grade-faces {
    display: flex;
    gap: 20px;
}

.grade-faces img {
    width: 30px;
}

.grade-faces-big {
    display: flex;
    gap: 20px;
    justify-content: center;
}
.grade-faces-big img{
    width: 50px;
}

.no-walk {
    display: flex;
    flex-direction: column;
    gap: 10px;
    text-align: center;
    border-top: 1px solid #E9EEF4;
    padding: 15px 0;
}

.no-walk p {
    font-weight: 400;
    font-size: calc((100vw - 320px)/(425 - 320) * (12 - 10) + 10px);
    line-height: 14.52px;
    padding: 65.5px 43px;
    color: rgba(121, 121, 132, 1);
}

.thanks-for-gread {
    border-top: 1px solid #E9EEF4;
    padding: 15px 0;
    text-align: center;
    padding: 67px 0;
}

.thanks-for-gread p {
    font-weight: 400;
    font-size: calc((100vw - 320px)/(425 - 320) * (12 - 10) + 10px);
    line-height: 14.52px;
    color: rgba(121, 121, 132, 1);
}

.grade-faces-thanks p {
    font-weight: 400;
    font-size: calc((100vw - 320px)/(425 - 320) * (12 - 10) + 10px);
    line-height: 14.52px;
    color: rgba(121, 121, 132, 1);
}

.route_head {
    display: flex;
    padding-bottom: 15px;
    flex-direction: column;
    align-items: center;
}

.route_info_content {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: space-between;
}

.route_info_blocks {
    display: flex;
    gap: 15px;
}

.grade-faces-big svg.rate-1,
.grade-faces svg.rate-1 {
    fill: #EB2427;
}

.grade-faces-big svg.rate-2,
.grade-faces svg.rate-2 {
    fill: #F05C34;
}

.grade-faces-big svg.rate-3,
.grade-faces svg.rate-3 {
    fill: #ECA42F;
}

.grade-faces-big svg.rate-4,
.grade-faces svg.rate-4 {
    fill: #7AD81B;
}

.grade-faces-big svg.rate-5,
.grade-faces svg.rate-5 {
    fill: #4DE8B3;
}

.icon_small {
    width: 30px;
    height: 30px;
}