.sliderBackground {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  padding: 0 20px;
  /* width: 100%; */
  /* max-width: 455px; */
  margin: 0 auto;
  background: rgb(14, 50, 126);
  background: -moz-linear-gradient(180deg,
      rgba(14, 50, 126, 1) 3%,
      rgba(33, 68, 180, 1) 37%,
      rgba(190, 107, 197, 1) 100%);
  background: -webkit-linear-gradient(180deg,
      rgba(14, 50, 126, 1) 3%,
      rgba(33, 68, 180, 1) 37%,
      rgba(190, 107, 197, 1) 100%);
  background: linear-gradient(180deg,
      rgba(14, 50, 126, 1) 3%,
      rgba(33, 68, 180, 1) 37%,
      rgba(190, 107, 197, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0e327e", endColorstr="#be6bc5", GradientType=1);
}

.closeSlider {
  position: absolute;
  top: 20px;
  right: 7%;
  width: 20px;
  height: 20px;
  background-image: url('../../img/close.svg');
  background-color: inherit;
  border: none;
}

.slider {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20%;
  height: 100%;
  /* width: 200px; */
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider::-webkit-scrollbar {
  display: none;
}

.carouselCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px 30px 50px;
  border-radius: 10px;
  flex: 0 0 auto;
  width: 82%;
  height: 65%;
  text-align: center;
  color: #0A0930;

  background: rgb(255, 255, 255);
  background: -moz-linear-gradient(180deg,
      rgba(255, 255, 255, 0.4962359943977591) 0%,
      rgba(255, 255, 255, 1) 50%);
  background: -webkit-linear-gradient(180deg,
      rgba(255, 255, 255, 0.4962359943977591) 0%,
      rgba(255, 255, 255, 1) 50%);
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.4962359943977591) 0%,
      rgba(255, 255, 255, 1) 50%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff", endColorstr="#ffffff", GradientType=1);
}

.carouselSvg {
  width: 80%;
  height: 80%;
  pointer-events: none;
}

.navDots {
  display: flex;
  gap: 5px;
  margin-top: 8.5px;
  margin-bottom: 8.5px;
}

.dot {
  border: none;
  background: none;
}

.active-dot {
  fill: #523DD8;
}

.carouselTopText {
  font-size: calc((100vw - 320px)/(480 - 320) * (24 - 16) + 16px);
  font-weight: 500;
  stroke: #F5F5F5;
  padding-bottom: 5px;
}

.carouselBotText {
  font-size: calc((100vw - 320px)/(480 - 320) * (13 - 11) + 11px);
  font-weight: 300;
}

.blockSupport {
  opacity: 0;
  width: 1px;
}



