@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
}
body {
  font-family: 'inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("img/bg.svg");
  background-size: 100%;
  overflow: hidden;
  /* overflow-y: scroll;
    overflow-x: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none; */
}

body::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input, select, textarea {
  outline: none;
  -webkit-transition: font-size 0.5s;
  -moz-transition: font-size 0.5s;
  -o-transition: font-size 0.5s;
  transition: font-size 0.5s;
}

button:focus {
  outline: none;
  box-shadow: none;
}