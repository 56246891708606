.footer_block {
    position: absolute;
    z-index: 1;
    display: inline-flex;
    width: 80%;
    justify-content: space-between;
    background-color: rgba(230, 234, 243, 0.5);
    -webkit-backdrop-filter: blur(50px);
    backdrop-filter: blur(50px);
    color: #797984;
    bottom: 20px;
    left: 10px;
    margin: 0 auto;
    padding: 6.5px 0;
    text-align: center;
    border-radius: 10px;
    font-size: 12px;
    width: calc(100% - 20px);
}
.footer_block.active {
    display: none;
}

.no_underline_footer {
    text-decoration: none;
    color: #797984;
    width: calc(100%/3);
}

.footer_block p {
    padding: 0;
    margin-top: 3px;
    font-weight: 300;
}

.footer_main.active {
   color: #523DD8;
}

.footer_main.active svg g path {
    fill: #523DD8;
}

.footer_trips.active {
    color: #523DD8;
}

.footer_trips.active svg path {
    fill: #523DD8;
}

.no_underline_footer {
    text-decoration: none;
    cursor: pointer;
}

.footer_invite {
    display: block;
    align-items: center;
}

.notification {
    position: fixed;
    bottom: 90px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(23, 37, 99, 0.7);
    color: white;
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
}
