.que_body {
    background: #fff;
    border-radius: 15px 15px 0px 0px;
    padding-left: 3%;
    padding-right: 3%;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.que_scroll {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-bottom: 100px;
}

.que_body h3 {
    padding-top: 15px;
    padding-bottom: 5px;
    font-size: 18px;
    font-weight: 500;
    color: #0A0930;
    text-align: center;
}

.que_block {
    width: 100%;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: #F5F5F5;
    border-radius: 10px;
    justify-content: space-between;
}

.que_block_answ {
    /* margin-top: 3%; */
    width: 100%;
    display: flex;
    gap: 10px;
    background-color: #F5F5F5;
    border-radius: 10px 10px 0px 0px;
    align-items: center;
    justify-content: space-between;
}

.que_text {
    padding: 10px 0 10px 10px;
    padding-left: 10px;
    padding-right: 0;
    font-size: 15px;
    width: 65%;
    color:#0A0930;
}


.que_answ_button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #523DD8;
    color: #ffffff;
    font-size: 13px;
    font-weight: 500;
    margin: 10px 10px 10px 0;
    padding: 0 10px;
    border-radius: 10px;
    border: none;
    height: 36px;
    width: 92px!important;
}

.que_answ_button_hidden {
    display: none;
}

.que_answ_show {
    display: block;
    margin-top: 3px;
    background-color: #F5F5F5;
    border-radius: 0px 0px 10px 10px;
    padding: 10px;
    color: #797984;
}

.que_answ_hidden {
    display: none;
}

.server_wait {
    position: absolute;
    top: 50%;
    right: 45%;
    text-align: center;

    animation: rotate 1.5s infinite linear;
    -webkit-animation: rotate 1.5s infinite linear;
}

@keyframes rotate {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@-webkit-keyframes rotate {
    0% {
        transform: rotate(360deg);
    }

    100% {
        transform: rotate(0deg);
    }
}