.start_body {
    margin-top: 25px;
    color: #ffffff;
    width: 100%;
    margin-bottom: 70px;
    display: flex;
}

.start_hello {
    margin-left: 20%;
    font-size: 20px;
    font-weight: 300;
    font-style: italic;
}

.start_menu {
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
    font-size: 10px;
    text-align: center;
}

.start_menu p {
    margin: 0;
    padding: 0;
    margin-top: 3px;
}

.no-underline {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
}

.start_btn_rules {
    position: relative;
    z-index: 6;
}

.ruleShadow {
    text-shadow: 0 0 5px white;
}

.start_btn_question {
    width: min-content;
}