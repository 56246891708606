.selectPage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 40px;
    padding: 0 60px;
}

.forLogo {
    width: 90%;
}

.forLogo img {
    width: 100%;
}

.selectContainer {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    padding: 30px 40px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.25) 0%, rgba(255, 255, 255, 0.5) 45.38%);
    width: 100%;
    gap: 12px;
}

.selectBtn {
    background: #B5A5CD;
    border: none;
    border-radius: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 20px; */
    padding: 7px 0;
}

.forImgSelect {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    /* padding-right: 20px; */
}

.forImgSelect img {
    padding-right: 20px;
}

.selectBtn p {
    width: 100%;
    font-size: 20px;
    color: white;   
    text-align: left; 
}