.header_block {
  padding-top: 15px;
  padding-bottom: 15px;
  display: inline-flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  font-size: 15px;
  background: rgb(23,37,99);
  background: -moz-linear-gradient(180deg, rgba(23,37,99,0.8) 0%, rgba(23,37,99,0) 100%);
  background: -webkit-linear-gradient(180deg, rgba(23,37,99,0.8) 0%, rgba(23,37,99,0) 100%);
  background: linear-gradient(180deg, rgba(23,37,99,0.8) 0%, rgba(23,37,99,0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#172563",endColorstr="#172563",GradientType=1);
}
.header_block.active {
  display: none;
}

.header_logo {
  cursor: pointer;
  padding-left: 20px;
}

.header_text {
  color: #ffffff;
}

.header_user_name {
  color: #FFFFFF;
  font-weight: 300;
  font-size: 15px;
  width: fit-content;
  text-align: center;
}

.header_user {
  background-color: #ffffff;
  text-align: center;
  border-radius: 25px;
  font-size: 10px;
  display: inline-flex;
  gap: 5px;
  padding-left: 10px;
  padding-right: 2px;
  align-items: center;
  font-weight: 400;
  line-height: 10px;
  height: 29px;
}

/* .header_user .first {
    
} */

/* .header_user .second {
   
} */

.header_bold {
  font-weight: 900;
}

.second {
  width: 25px;
  height: 25px;
  border-radius: 25px;
  overflow: hidden;
  position: relative;
}

.account_photo {
  width: 100%;
  height: auto; 
  object-fit: cover;
  object-position: center;
}

.header_lang_user {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  align-self: center;
  width: fit-content;
}

.header_lang_user button {
  background: none;
  border: none;
  padding: 0;
  margin: 4px 0;
}