.rules_body {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: 10px 10px 0 0;
    padding: 15px 10px 0;
    gap: 15px;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    touch-action: manipulation;
    overscroll-behavior: contain;
    color: #0A0930;
}

.rules_body::-webkit-scrollbar {
    display: none;
}

.rules_body h1 {
    font-size: 18px;
}

.points {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
}

.point {
    display: flex;
    gap: 10px;
    align-items: flex-start;
}

.ellips {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #523DD8;
    font-weight: 800;
    font-size: 15px;
    min-width: 50px;
}

.rule {
    font-size: 13px;
    line-height: 18.15px;
}

.enjoy {
    text-align: center;
    font-size: 13px;
    width: 100%;
}

.update_page {
    color: #797984;
    font-size: 10px;
    padding-bottom: 110px;
}

.post {
    font-size: 13px;
    font-weight: 600;
}

.cube {
    font-weight: 500;
    background-color: #523DD8;
    color: white;
    padding: 8px;
    border-radius: 10px;
}