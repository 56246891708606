.glassDevelopment {
    position: absolute;
    top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(93, 117, 164, 0.8);
  backdrop-filter: blur(2px);
  z-index: 2;
}


.developmentCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-radius: 10px;
  width: 70%;
  text-align: center;
  color: #0a0930;
  flex: none;
  scroll-snap-align: start;

  background: #fff;
}

.closeBlock {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
.closeDevelopment {
  width: 20px;
  height: 20px;
  background-image: url("../../img/closeDevelopment.svg");
  background-color: inherit;
  border: none;
  cursor: pointer;
}

.inCard {
  padding: 75px 45.72px 35px;
}

.developmentSvg {
  width: 100%;
  height: 100%;
}

.developmentText {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 20px;
  padding-right: 20px;
}
.developmentTopText {
  font-size: calc((100vw - 320px) / (480 - 320) * (20 - 16) + 16px);
  font-weight: 500;
  stroke: #f5f5f5;
}
.developmentBotText {
  font-size: calc((100vw - 320px) / (480 - 320) * (13 - 11) + 11px);
  font-weight: 300;
}
