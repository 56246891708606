@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

.trip_body {
    width: 94%;
    background: rgb(233, 238, 244);
    background: -moz-linear-gradient(180deg, rgba(233, 238, 244, 1) 0%, rgba(245, 245, 245, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(233, 238, 244, 1) 0%, rgba(245, 245, 245, 1) 100%);
    background: linear-gradient(180deg, rgba(233, 238, 244, 1) 0%, rgba(245, 245, 245, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9eef4", endColorstr="#f5f5f5", GradientType=1);
    padding-left: 3%;
    padding-right: 3%;
    border-radius: 15px 15px 0px 0px;
    position: relative;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    /* IE и Edge */
    scrollbar-width: none;
    /* Firefox */
}

.trip_body::-webkit-scrollbar {
    display: none;
}

.blur-content {
    filter: blur(5px);
}

.block_none {
    display: none;
}

.content {
    transition: filter 0.5s ease-in-out;
}

.trip_filters {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}

.trip_add {
    border: none;
    background-color: #523DD8;

    color: #ffffff;
    font-size: 13px;
    font-weight: 400;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
}

.trip_add.disabled {
    background-color: #AFAFB5;
    /* Цвет для недоступной кнопки */
    cursor: not-allowed;
}

.trip_add span {
    margin-right: 5px;
}

.slide-down {
    top: 0;
}

.slide-up {
    top: -100%;
}

.filter_options {
    position: fixed;
    /* top: -100%; */
    width: 94%;
    margin-left: -3%;
    background-color: #ffffff;
    z-index: 2;
    padding-top: 2%;
    padding-right: 3%;
    padding-left: 3%;
    border-radius: 0px 0px 15px 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: top 0.8s ease-in-out;
    filter: none;
}

.filter_options p {
    margin: 0;
    margin-top: 7px;
    margin-bottom: 5px;
    font-size: 13px;
}


.filter_header {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.filter_close {
    position: fixed;
    top: 3%;
    right: 2%;
}

.close_button {
    border: none;
    background: none;
}

.filter_city_select {
    display: inline-block;
    padding: 0 34px 0 10px;
    height: 35px;
    font-size: 13px;
    color: #2c2c2c;
    /* Цвет текста */
    border: 1px solid #e1e1e1;
    /* Цвет границы */
    border-radius: 10px;
    /* Радиус скругления */
    appearance: none;
    /* Убирает стандартный вид селекта */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="%232c2c2c" stroke-width="2" d="M6 9l6 6 6-6"/></svg>');
    /* Стрелка */
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
    text-align: center;
    text-align-last: center;
    margin: 0 auto;
}

.filter_city_select:focus {
    border-color: #007bff;
    /* Цвет границы при фокусе */
    outline: none;
}

.filter_city_select option {
    padding: 10px;
}

.filter_date_trip {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
}

.filter_date_select {
    display: inline-block;
    width: 32%;
    padding: 10px;
    font-size: 12px;
    color: #2c2c2c;
    /* Цвет текста */
    border: 1px solid #e1e1e1;
    /* Цвет границы */
    border-radius: 10px;
    /* Радиус скругления */
    appearance: none;
    /* Убирает стандартный вид селекта */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="%232c2c2c" stroke-width="2" d="M6 9l6 6 6-6"/></svg>');
    /* Стрелка */
    background-repeat: no-repeat;
    background-position: right 10px center;
    cursor: pointer;
    text-align: center;
    text-align-last: center;
}

.filter_time_trip {
    display: inline-flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.filter_time_select {
    display: inline-block;
    width: 100%;
    padding: 10px;
    font-size: 12px;
    color: #797984;
    /* Цвет текста */
    border: 1px solid #e1e1e1;
    /* Цвет границы */
    border-radius: 10px;
    /* Радиус скругления */
    appearance: none;
    /* Убирает стандартный вид селекта */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #797984;
    /* background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="%232c2c2c" stroke-width="2" d="M6 9l6 6 6-6"/></svg>');
    background-repeat: no-repeat;
    background-position: right 10px center; */
    cursor: pointer;
    text-align: center;
    text-align-last: center;
}

.filter_sex_trip {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-right: 6%;
}

.filter_sex_btn {
    display: flex;
    align-items: center;
    width: 32%;
}

.filter_sex_btn label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #EAEAEA;
    color: #797984;
    padding: 10px 3px;
    border-radius: 10px;
    text-align: center;
    align-items: center;
    font-size: 12px;
    transition: background-color 0.3s ease;
}

.filter_sex_btn label svg {
    margin-right: 3px;
}

.filter_sex_btn input[type=checkbox] {
    display: none;
}

.filter_sex_btn input[type=checkbox]:checked+label,
.filter_sex_btn input[type=checkbox]:checked+label svg path {
    background: #CFDAFF;
    color: #0912DB;
    fill: #0912DB;
}

.filter_buttons {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
    font-size: 13px;
    font-weight: 400;
}


.reset_button {
    border: none;
    background: #D6D6D6;
    color: #000000;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    margin-top: 20px;
}

.reset_button:active {
    background-color: #C2C2C2;
}

.save_button {
    display: block;
    border: none;
    background: #523DD8;
    color: #ffffff;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    margin-top: 5px;
}

.save_button:active {
    background-color: #7865EE;
}

.fail_button {
    display: block;
    border: none;
    background: #A8A8AC;
    color: #ffffff;
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    margin-top: 5px;
}

.search_result_item {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    margin-top: 1%;
    border-radius: 10px;
    align-items: center;
}

.search_result_item:first-child {
    margin-top: 2%;
}

.result_item_left {
    margin: 10px;
    color: #0A0930;
    width: 170px;
}

.result_item_left span {
    font-size: 10px;
    color: #797984;
}

.result_item_left p {
    margin: 0;
    padding: 0;
    font-size: 13px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.result_item_left h4 {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    padding-right: 10px;
}

.trip_about_button {
    padding: 0;
    border: none;
    border-radius: 10px;
    background: #523DD8;
    color: #ffffff;
    margin-right: 10px;
    padding: 10px;
}

.search_result_item:last-child {
    margin-bottom: 80px;
}

.route_info_slide_in {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    /* transform: translateY(100%);
    animation: slideIn 0.5s forwards; */
}

.route_info_content {
    padding: 0;
    display: block;
    padding-top: 10px;
    width: 100%;
}

.route_close {
    position: absolute;
    left: 10px;
}

.close_button {
    display: block;
    margin-top: 15px;
}

@keyframes slideIn {
    from {
        transform: translateY(1000px);
    }

    to {
        transform: translateY(0);
    }
}

.route_header {
    display: flex;
}

.route_info_id {
    font-size: 10px;
    font-weight: 400;
    color: #797984;
    text-align: center;
}

.route_info_date {
    font-size: 13px;
    font-weight: 400;
    color: #0A0930;
    text-align: center;
}

.route_info_content h4 {
    font-size: 15px;
    font-weight: 400;
    color: #0A0930;
    text-align: center;
    padding-left: 5px;
    padding-right: 5px;
}

.route_info_blocks {
    display: flex;
    justify-content: space-between;
    margin-top: 7px;
    width: calc(100% - 20px);
    align-items: center;
    text-align: center;
    color: #0A0930;
}

.route_info_block {
    padding: 10px;
    background: #E9EEF4;
    border-radius: 10px;
    text-align: center;
    width: 100%;
}

.route_info_ages {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #CFDAFF;
    color: #0912DB;
    justify-content: center;
}

.route_info_sex {
    display: flex;
    align-items: center;
    gap: 2px;
}

.route_info_small {
    font-size: 16px;
    color: #797984;
}

.attractions {
    font-size: 9px;
    font-weight: 300;
    text-align: center;
    padding-bottom: 5px;
}

.phone_map {
    width: 100%;
    height: 30%;
    cursor: pointer;
}

.route_articles {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    margin: 0 auto;
    margin-top: 5px;
    gap: 5px;
    align-items: center;
    margin-bottom: 5px;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.article {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #E9EEF4;
    border-radius: 10px;
    align-items: center;
}

.article_name {
    font-size: 15px;
    margin: 10px;
    font-weight: 400;
}

.article_about {
    border: none;
    background-color: #523DD8;
    padding: 10px;
    border-radius: 10px;
    color: #fff;
    margin: 10px;
}

.yandex_map {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.yandex_map a {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    justify-content: center;
}

.yandex_map a img {
    width: 100%;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.yandex_block {
    width: 100%;
    display: flex;
    justify-content: center;
}

.route_bottom {
    width: 100%;
    position: absolute;
    bottom: 20px;
}

.route_travelers {
    margin-left: 10px;
    margin-right: 10px;
}

.route_travelers h4 {
    margin: 0;
    padding: 0;
    font-size: 13px;
    font-weight: 400;
}

.footer_nav {
    display: flex;
    align-items: center;
    width: 100%;
    flex-direction: column;
    gap: 15px;
}

.route_button_chat {
    display: block;
    width: calc(100% - 20px);
}

.route_button_chat .button_chat {
    border: none;
    padding: 10px;
    color: #ffffff;
    background: #523DD8;
    width: 100%;
    border-radius: 10px;
}

.route_share {
    display: flex;
    align-items: center;
    width: calc(100% - 20px);
    gap: 10px;
    font-size: 10px;
    color: #616161;
    cursor: pointer;
}

.button_registr {
    border: none;
    padding: 10px;
    color: #ffffff;
    background: #46BED9;
    width: 100%;
    margin-right: 10px;
    border-radius: 10px;
}

.button_leave_trip {
    border: none;
    padding: 10px;
    color: #ffffff;
    background: #46BED9;
    width: 100%;
    margin-right: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.button_full {
    border: none;
    padding: 10px;
    color: #ffffff;
    background: #AFAFB5;
    width: 100%;
    margin-right: 10px;
    border-radius: 10px;
}

.route_register_text {
    margin: 0;
    padding: 0;
    color: #797984;
    font-size: 10px;
    font-weight: 400;
    margin-top: 5px;
}

.result_item_part {
    color: #797984;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
}

.header_bottom {
    border-bottom: 1px solid #E9EEF4;
    padding-bottom: 5px;
}

.chat_messages {
    background-blend-mode: multiply;
    background: url("../../img/bg_search.svg") no-repeat;
    background-size: 100%, calc(100% - 20px);
    background-position: bottom;
    background-color: #ffffff;
    margin-left: 10px;
    margin-right: 10px;
    border-radius: 15px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding: 10px;
}

.chat_messages::-webkit-scrollbar {
    display: none;
}

.chat_support_block {
    justify-content: center;
}

.chat_message_support {
    font-family: "Inter", sans-serif;
    font-optical-sizing: auto;
    font-weight: 500;
    font-style: normal;
    display: flex;
    flex-direction: column;
    color: #797984;
    padding: 10px;
    gap: 5px;
    border-radius: 10px;
    margin-left: 30px;
    margin-right: 30px;
    align-items: center;
    text-align: center;
    font-size: 13px;
}

.chat_message {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;
    margin-top: 10px;
}

.chat_message.user {
    justify-content: flex-end;
}

.chat_user_message {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    background: #E9EEF4;
    border-radius: 15px;
    max-width: 75%;
    word-wrap: break-word;
    word-break: break-word;
    overflow-wrap: anywhere;
}

.chat_user_message span {
    font-size: 9px;
    font-weight: 300;
}

.chat_user_message.user {
    background: #CFDAFF;
    align-items: flex-end;
}

.chat_user_message.user span {
    justify-content: flex-end;
}

.chat_user_photo {
    margin-top: 5px;
    width: 25px;
    height: 25px;
    border-radius: 25px;
}

.chat_message.user .chat_user_photo {
    order: 2;
}

.chat_footer {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
}

.chat_input {
    flex-grow: 1;
    margin-right: 10px;
}

.chat_input input {
    width: 100%;
    border: none;
    border-radius: 10px;
    background: #E9EEF4;
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;
}

.chat_send {
    flex-shrink: 0;
    height: 100%;
    background: #CFDAFF;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 10px;
}
