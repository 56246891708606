.profile {
    display: flex;
    flex-direction: column;
    height: 100%;
    /* height: 500px; */
}

.preview {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    padding-bottom: 10px;
    height: 30vh;
}   

.ton {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    background-color: rgba(0, 0, 0, 0.05);
    background-blend-mode: overlay;
}

.photo_block {
    width: 100%;
    height: 350px;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: -2;
}

.avatar {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}


.ageAndSex {
    background-color: rgba(207, 218, 255, 0.9);
    display: flex;
    align-items: center;
    gap: 10px;
    padding: calc((100vw - 320px) / (425 - 320) * (10 - 8) + 8px) calc((100vw - 320px) / (425 - 320) * (20 - 16) + 16px);
    margin-right: 10px;
    border-radius: 10px;
    font-size: calc((100vw - 320px) / (425 - 320) * (20 - 13) + 13px);
    font-weight: 300;
    color: #0912DB;
}

.sex {
    display: flex;
    align-items: center;
}

.camera {
    background-color: rgba(207, 218, 255, 1);
    margin-right: 10px;
    border-radius: 10px;
    border: none;
    padding: 10px;
    height: 50px;
    cursor: pointer;
}

.profile_body {
    touch-action: auto;
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    width: 100%;
    height: calc(100vh - 30vh - 60.5px);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.profile_body::-webkit-scrollbar {
    display: none;
}

.scroll_container {
    background: rgb(233, 238, 244);
    /* background: -moz-linear-gradient(180deg, rgba(233, 238, 244, 1) 0%, rgba(245, 245, 245, 1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(233, 238, 244, 1) 0%, rgba(245, 245, 245, 1) 100%);
    background: linear-gradient(180deg, rgba(233, 238, 244, 1) 0%, rgba(245, 245, 245, 1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9eef4", endColorstr="#f5f5f5", GradientType=1); */
    border-radius: 10px 10px 0 0;
    /* padding: 15px 10px 0; */
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.name_block {
    padding-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
}

.profile_name {
    color: #0A0930;
    font-size: calc((100vw - 320px) / (425 - 320) * (25 - 15) + 15px);
    font-weight: 400;
}

.telegram_username {
    font-size: calc((100vw - 320px) / (425 - 320) * (15 - 13) + 13px);
    ;
    font-weight: 500;
    color: #797984;
}

.profile_route_info_blocks {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: calc(100% - 20px);
}

.route_info_block_profile {
    padding: calc((100vw - 320px) / (425 - 320) * (10 - 6) + 6px);
    background: #fff;
    border-radius: 10px;
    text-align: center;
    width: 100%;
    font-size: calc((100vw - 320px) / (425 - 320) * (20 - 15) + 15px);
}

.route_info_ages {
    background: #CFDAFF;
    color: #0912DB;
}


.span_small {
    font-weight: 300;
    color: #0912DB;
}

.profile_stat {
    width: calc(100% - 40px);
    background-color: #ffffff;
    padding-top: calc((100vw - 320px) / (425 - 320) * (34 - 18) + 18px);
    ;
    padding-bottom: calc((100vw - 320px) / (425 - 320) * (34 - 18) + 18px);
    ;
    border-radius: 15px;
    box-shadow: 10px 5px 5px rgba(43, 44, 46, 0.2);
    padding-right: 10px;
    padding-left: 10px;
}

.friends_contain {
    display: flex;
    width: calc(100% - 20px);
    gap: 10px;
    align-items: start;
    flex-direction: column;
}

.friends_block {
    padding-bottom: 75px;
    width: calc(100% - 20px);
    display: flex;
    gap: 10px;
    flex-direction: column; 
    align-items: flex-start;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.friends_block::-webkit-scrollbar {
    display: none;
}

.friends_text {
    font-size: 13px;
    width: calc(100% - 20px);
    color: #0A0930;
}

.friends_text span {
    color: #797984;
}

.friends {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    gap: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.friends::-webkit-scrollbar {
    display: none;
}

.friend {
    width: 72px;
    height: 96px;
}

.friend_avatar {
    width: 72px;
    height: 72px;
    border-radius: 100px;
}

.friend_level {
    position: relative;
    top: -10px;
    left: 15%;
    border-radius: 10px;
    background-color: #fff;
    width: 50px;
    height: 10px;
    font-size: 8px;
    font-weight: 900;
    color: #523DD8;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);;
}

.edit_button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    bottom: 20px;
    padding: 10px 0;
    color: #fff;
    border: none;
    border-radius: 10px;
    background-color: #523DD8;
    width: calc(100% - 20px);
    cursor: pointer;
}

.telegram {
    padding-top: 15px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.back {
    position: absolute;
    left: 10px;
    cursor: pointer;
}

.input_block {
    display: flex;
    width: calc(100% - 20px);
    gap: 5px;
}

.input_names {
    display: flex;
    align-items: center;
    gap: 5px;
}

.input_name {
    font-size: 13px;
    color: #0A0930;
    width: 100%;
}

.inputs {
    display: grid;
    grid-template-columns: 1fr 2.2fr;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.you_name {
    border: none;
    border-radius: 10px;
    height: 40px;
    padding: 0 10px;
}

input::placeholder {
    font-size: 13px;
    color: #797984;
}

.filter_date_trip_profile {
    display: flex;
    gap: 10px;
}

.filter_date_select_profile {
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 40px;
    padding: 0 18px 0 5px;
    font-size: 13px;
    color: #2c2c2c;
    appearance: none;
    /* Убирает стандартный вид селекта */
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24"><path fill="none" stroke="%232c2c2c" stroke-width="2" d="M6 9l6 6 6-6"/></svg>');
    /* Стрелка */
    background-repeat: no-repeat;
    background-position: right 5px center;
    cursor: pointer;
    text-align: center;
    text-align-last: center;
    text-overflow: ellipsis;
    overflow: hidden;
}

.change_sex {
    display: flex;
    gap: 5px;
}

.button_sex {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: rgba(207, 218, 255, 0.5);
}

.button_sex:focus {
    background-color: rgba(207, 218, 255, 1);
    padding: 5px;
}

.button_sex:focus path {
    width: 30px;
    height: 30px;
    fill: rgba(82, 61, 216, 1);
}

.button_sex:focus svg {
    width: 30px;
    height: 30px;
}

.sex_icon {
    fill: rgba(82, 61, 216, 0.5);
}

.ps {
    font-size: 10px;
    color: #797984;
    width: calc(100% - 20px);
}

.ps span {
    text-decoration: underline;
}

.bottom_button {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 5px;
    bottom: 20px;
    padding: 0 20px;
    width: calc(100% - 20px);
}

.bottom_button_none {
    display: none;
}

.update_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #0912DB;
    border: none;
    border-radius: 10px;
    background-color: #CFDAFF;
    padding: 10px 0;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 5px;
}

.save_profile_button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: #fff;
    border: none;
    border-radius: 10px;
    background-color: #523DD8;
    padding: 10px 0;
    cursor: pointer;
}

.save_profile_button:disabled {
    background-color: #AFAFB5;
}

.stop_ham {
    position: absolute;
    bottom: -150px;
    background: rgb(233, 238, 244);
    height: 300px;
    width: 100%;
    z-index: -1;
}

.checkboxContainer {
    display: flex;
    align-items: flex-start;
    gap: 7px;
}

.checkboxAccept {
    height: 12px;
    width: 12px;
    border: 1px solid #cfdaff;
    background: #fff;
    border-radius: 3px;
    transform: translate(1px, 2px);
}

.accept {
    position: relative;
    transform: translate(-1px, -4px);
}