.glass {
  backdrop-filter: blur(1px);
  background-color: rgb(83 109 161 / 80%);
  position: absolute;
  z-index: 5;
  height: 100%;
  width: 100%;
  top: 0;
}

.closeGlass {
  position: absolute;
  z-index: 3;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    background: none;
    border: none;
}

.union {
  position: absolute;
  top: 52px;
  left: calc((100vw - 320px) /(425 - 320)*(26 - 15) + 20%);
}

.forStart {
  position: absolute;
  top: 190px;
  right: 20px;
  text-align: center;
  font-size: 12px;
  border-radius: 10px;
  width: 271px;
  padding: 15px 10px;
  background-color: white;
  line-height: 14.52px;
}

.home_body {
  width: 100%;
  background: rgb(233,238,244);
  background: -moz-linear-gradient(180deg, rgba(233,238,244,1) 0%, rgba(245,245,245,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(233,238,244,1) 0%, rgba(245,245,245,1) 100%);
  background: linear-gradient(180deg, rgba(233,238,244,1) 0%, rgba(245,245,245,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e9eef4",endColorstr="#f5f5f5",GradientType=1);
  border-radius: 15px 15px 0px 0px;
  
}

.home_stat {
  position: relative;
  width: 60%;
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-right: 10px;
  padding-left: 10px;
  border-radius: 10px;
  z-index: 1;
  top: -50px;
  box-shadow: 10px 5px 5px rgba(43, 44, 46, 0.2);
}

.home_level {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  word-spacing: 10px;
  padding-bottom: 15px;
}

.home_done {
  width: 100%;
  display: inline-flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 14px;
  padding-bottom: 5px;
}

.home_distanse_need {
  color: #797984;
}

.home_progress {
  width: 100%;
  display: flex;
}

.home_progress progress {
  background-color: #523dd8;
  height: 7px;
  width: 100%;
  border: 0;
  border-radius: 60px;
}

.home_progress progress::-webkit-progress-bar {
  background-color: #797984;
  border: 0;
  border-radius: 60px;
}

.home_progress progress::-webkit-progress-value {
  background-color: #523dd8;
  border: 0;
  border-radius: 60px;
}

.home_scroll {
  overflow-x: hidden;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.home_scroll::-webkit-scrollbar {
  display: none;
}

.home_history {
  position: relative;
  top: -40px;
  padding-left: 3%;
  padding-right: 3%;
  

}

.home_history h3 {
  font-size: 18px;
  font-weight: 500;
  color: #0a0930;
  margin-bottom: 0;
  padding-bottom: 0;
}

.home_search_result {
  width: 100%;
  /* padding-bottom: 100%; */
  /* height: 300px; */
  /* padding-bottom: 0%; */
  background-blend-mode: multiply;
  background: url("../../img/bg_search.svg") no-repeat;
  background-size: 100%, calc(100% - 20px);
  background-position: bottom;
  background-color: #ffffff;
  border-radius: 15px;
  margin-top: 2%;
}

.home_search_result h2 {
  margin: 0;
  padding: 0;
  padding-top: 30px;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  color: #0a0930;
}

.home_search_result p {
  font-size: 13px;
  text-align: center;
  color: #797984;
}

.result_item_left h5 {
  margin: 0;
  padding: 0;
  font-size: 13px;
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: 400;
}

.res_item_button {
  padding: 0;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  margin-right: 10px;
  padding: 10px;
}

.result_about_button {
  background: #523DD8;
}

.result_rate_button {
  background: #46BED9;
}

.result_end_button {
  background: #AFAFB5;
}

.custom-top-style {
  top: 60px;
  z-index: 3;
  height: calc(100% - 60px);
}